#set-address-map .centerMarker {
  position: absolute;
  /*url of the marker*/
  background: url('/public/images/marker.svg') no-repeat;
  /*center the marker*/
  top: 50%;
  left: 50%;

  z-index: 1;
  /*fix offset when needed*/
  margin-left: -10px;
  margin-top: -34px;
  /*size of the image*/
  height: 48px;
  width: 32px;

  cursor: pointer;
}

#set-address-map-res .centerMarkerRes {
  position: absolute;
  /*url of the marker*/
  background: url('/public/images/marker.svg') no-repeat;
  /*center the marker*/
  top: 50%;
  left: 50%;

  z-index: 1;
  /*fix offset when needed*/
  margin-left: -10px;
  margin-top: -34px;
  /*size of the image*/
  height: 48px;
  width: 32px;

  cursor: pointer;
}

.gm-style-cc {
  display: none;
}

.pac-container {
  top: 5%;
  border-radius: 20px;
  margin-top: 12px;
  max-height: 200px;
  overflow-y: auto;
}

.pac-item {
  padding: 18px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: none;
}

.pac-item-query {
  width: 90%;
  font-family: 'Poppins', 'sans-serif';
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
}

.pac-item span ~ span ~ span {
  width: 100%;
  margin-left: 10%;
  font-family: 'Poppins', 'sans-serif';
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-overflow: ellipsis;
  width: 90%;
  overflow: hidden;
}

.pac-matched {
  margin-left: 0 !important;
}

.pac-icon {
  background: url('/public/images/marker-outline.svg') no-repeat !important;
  background-position: 10px 2px !important;
  width: 24px;
  height: 24px;
  margin-top: 0;
  margin-right: 0;
  width: 10%;
}

.pac-logo:after {
  display: none;
}

.grecaptcha-badge {
  visibility: hidden;
}
