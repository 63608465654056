.carousel .control-dots {
  text-align: left;
  margin: 40px 0 30px 40px;
}

.carousel .control-dots .dot {
  width: 8px;
  height: 8px;
  margin: 0 6px;
  opacity: 0.5;
  box-shadow: none;
}

.slider .carousel {
  margin: -1px;
  border: 1px solid transparent;
  border-radius: 20px;
  isolation: isolate;
}

.slider .carousel .control-next.control-arrow:before {
  content: '';
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 8px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.slider .carousel .control-prev.control-arrow:before {
  content: '';
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 8px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.slider .carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}

.slider .carousel .control-prev.control-arrow {
  left: 36px;
}

.slider .carousel .control-next.control-arrow {
  right: 36px;
}

.slider .carousel .control-arrow {
  opacity: 0.4;
}

.slider .carousel:hover .control-arrow {
  opacity: 1;
  filter: alpha(opacity=100);
}

@media screen and (max-width: 640px) {
  .slider .carousel.carousel-slider {
    height: 225px;
  }

  .slider .carousel .slider-wrapper {
    border-radius: 20px;
    isolation: isolate;
  }

  .slider .carousel .control-prev.control-arrow {
    display: none;
  }

  .slider .carousel .control-next.control-arrow {
    display: none;
  }

  .slider .carousel .control-dots .dot {
    margin: 0 6px;
  }

  .carousel .control-dots {
    text-align: center;
    margin: 0;
    bottom: 4%;
  }

  .carousel .control-dots .dot {
    background-color: #c3c3c3;
  }

  .carousel .control-dots .dot.selected {
    background-color: #ab3224;
  }

  .carousel.carousel-slider {
    overflow: inherit;
  }

  .carousel .control-next.control-arrow,
  .carousel .control-next.control-arrow:hover {
    background-color: #fffbf8;
    right: 0;
  }

  .carousel .control-prev.control-arrow,
  .carousel .control-prev.control-arrow:hover {
    background-color: #fffbf8;
    left: 0;
  }

  .carousel .control-arrow,
  .carousel.carousel-slider .control-arrow {
    opacity: 1;
  }

  .carousel .control-next.control-arrow:before {
    content: '';
    border: solid #ab3224;
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 10px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .carousel .control-prev.control-arrow:before {
    content: '';
    border: solid #ab3224;
    border-width: 0 5px 5px 0;
    display: inline-block;
    padding: 10px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .testi-carousel .thumbs {
    transform: none !important;
  }

  .testi-carousel .carousel .control-prev.control-arrow {
    display: none;
  }

  .testi-carousel .carousel .control-next.control-arrow {
    display: none;
  }

  .carousel .thumbs-wrapper {
    margin: 0 !important;
  }

  .carousel .thumb.selected {
    border-radius: 5px !important;
    isolation: isolate;
  }

  .carousel .thumb {
    min-width: 60px !important;
    min-height: 60px !important;
    max-width: 60px !important;
    max-height: 60px !important;
    display: flex;
    justify-content: center;
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
  }

  .carousel .thumb:nth-child(1) img {
    width: 45px !important;
  }

  .carousel .thumb:nth-child(2) img {
    width: 45px !important;
  }

  .carousel .thumb:nth-child(3) img {
    width: 50px !important;
  }

  .carousel .thumb:nth-child(4) img {
    width: 50px !important;
  }

  .carousel .thumb:nth-child(5) img {
    width: 30px !important;
  }

  .carousel .thumb:nth-child(6) img {
    width: 30px !important;
  }

  .carousel .thumb:nth-child(7) img {
    width: 25px !important;
  }
}

.carousel .thumbs {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 12px 0;
}

.carousel .thumb {
  min-width: 125px;
  min-height: 125px;
  max-width: 125px;
  max-height: 125px;
  display: flex;
  justify-content: center;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
}

.carousel .thumb:hover {
  border-radius: 20px;
  isolation: isolate;
  box-shadow: 0px 2px 15px rgba(242, 242, 242, 1);
  opacity: 1;
  background-color: rgba(255, 254, 254, 1);
  border: none !important;
}

.carousel .thumb.selected {
  border-radius: 20px;
  isolation: isolate;
  box-shadow: 0px 2px 15px rgba(242, 242, 242, 1);
  opacity: 1;
  background-color: rgba(255, 254, 254, 1);
  border: none !important;
}

.carousel .thumb:hover {
  border: none !important;
}

.carousel .thumb div {
  display: flex;
  justify-content: center;
}

.carousel .thumb img {
  margin: auto;
}

.carousel .thumb:nth-child(1) img {
  width: 100px;
}

.carousel .thumb:nth-child(2) img {
  width: 100px;
}

.carousel .thumb:nth-child(3) img {
  width: 95px;
}

.carousel .thumb:nth-child(4) img {
  width: 85px;
}

.carousel .thumb:nth-child(5) img {
  width: 80px;
}

.carousel .thumb:nth-child(6) img {
  width: 75px;
}

.carousel .thumb:nth-child(7) img {
  width: 70px;
}
