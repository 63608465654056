@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./styles/index.css');
@import url('./styles/components/slider.css');
@import url('./styles/components/tippy.css');

@layer base {
  html,
  body {
    font-family: 'Poppins', 'sans-serif';
    -webkit-tap-highlight-color: transparent;
    position: relative;
    height: 100%;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
     -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
