input:focus-within ~ label {
  @apply transform scale-75 -translate-y-6 text-primary;
}

input:focus-within ~ label,
input:not(:placeholder-shown) ~ label {
  @apply transform scale-75 -translate-y-6;
}

input:focus-within ~ label {
  @apply text-primary;
}

.google-maps-input:placeholder-shown ~ div {
  @apply hidden;
}

.google-maps-input:not(:placeholder-shown) ~ div {
  @apply flex;
}
