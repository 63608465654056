.tippy-box[data-theme~='dark'] {
  background-color: black;
  color: white;
}

.tippy-box[data-theme~='dark'] > .tippy-arrow {
  color: black;
}

.tippy-css {
  pointer-events: all !important;
}
