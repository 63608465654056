/* Used For Chrome, Opera and Safari */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Used For Firefox: */
.no-scrollbar {
  scrollbar-width: none;
}
/* Used For MS Edge and IE: */
.no-scrollbar {
  -ms-overflow-style: none;
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 20px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #e6e6e6;
}

.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 100vh;
  border: 3px solid #e4e4e4;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #cacaca;
}